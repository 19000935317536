<template>
  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 card chat-box-area" v-if="!isLoadingRooms">
    <div class="phone-chat-header">
      <div class="back-chat-icon">
        <svg @click="back" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="back-chat-arrow"><path fill="#080025" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
        </svg>
      </div>
      <div class="chat-right-box-inner">
        <div class="first">
          <UserCard v-if="interlocutor" :user="interlocutor"></UserCard>
        </div>
      </div>
    </div>
    <div class="card-body chat-conversation-section scrollbar" ref="scrollSection" @click="loadNewMessages">
      <ScrollArea :settings="ScrollSettings" style="padding: 26px 10px 30px; display: flex; flex-direction: column; flex: 1; height: 100%; background-color: #f5f5f5;" class="messages-container">
        <div v-if="isLoadingMoreMessages" class="loading-previous-messages">
          <div class="loading-spinner">
            <div class="spinner"></div>
          </div>
          <span>Chargement des messages...</span>
        </div>
        
        <div v-if="hasMoreMessages && !isLoadingMoreMessages" class="load-more-messages" @click="loadMoreMessages">
          <span>Charger plus de messages</span>
        </div>
        
        <ChatMessage 
          v-for="message in formattedMessages" 
          :message="message" 
          :user_id="auth.id" 
          @retry-message="retryMessage"
        />
        <div v-if="coordonnee" class="make-payment-box">
          <i class="fas fa-exclamation"></i>
          <p>{{ $t("chatroom.cg") }} </p>
        </div>
      </ScrollArea>
    </div>
    <div class="chat-input-container" v-if="interlocutor">
      <div v-if="interlocutor && !interlocutor.lightyShareCertifed" class="certification-badge">
        <span class="certification-icon"></span>
        <span class="certification-description">
          {{ $t('certification-badge.description-vue', {firstname: interlocutor.prenom }) }}
              </span>
        <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
          {{ $t('certification-badge.more-content') }}
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-right">
              <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </a>
      </div>
      <div class="message-input">
        <textarea 
          ref="messageTextarea"
          placeholder="Votre message" 
          v-model="newMessages[roomId]" 
          @input="autosize" 
          @keydown.enter.exact.prevent="handleEnterKey"
          rows="1"
          :disabled="isSendingMessage"
        ></textarea>
        <button 
          class="send-button" 
          @click.prevent="sendMessage" 
          :disabled="!newMessages[roomId] || !newMessages[roomId].trim() || isSendingMessage"
        >
          <svg v-if="!isSendingMessage" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="send-icon">
            <line x1="22" y1="2" x2="11" y2="13"></line>
            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
          </svg>
          <span v-else class="button-spinner"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 chat-right-box" v-if="!isLoadingRooms">
    <div class="chat-right-box-inner">
      <div class="first">
        <UserCard v-if="interlocutor" :user="interlocutor"></UserCard>
      </div>
      <div v-if="interlocutor && !interlocutor.lightyShareCertifed" class="certification-badge">
        <span class="certification-title">
          <span class="certification-icon"></span>
          {{ $t('certification-badge.chat.title') }}
        </span>
        <span class="certification-description">
          {{ $t('certification-badge.chat.description', {firstname: interlocutor.prenom }) }}
              </span>
        <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
          {{ $t('certification-badge.more-content') }}
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-right">
              <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </a>
      </div>
      <div class="dernieres" v-if="roomLocations.length">
        <h4>{{ $t("chatroom.last") }}</h4>
        <LocationTicket v-for="location in formattedRoomLocations" :demandeLocation="location" :auth="auth"></LocationTicket>
      </div>
      <div class="dernieres" v-if="interlocutor.boutiqueActive">
        <div class="btn-area">
          <a :href="boutiqueLink" class="btn bn-primary">{{ $t("chatroom.shop") }}</a>
        </div>
      </div>
      <div class="dernieres" v-else>
        <div class="btn-area">
          <a :href="boutiqueLink" class="btn bn-primary">{{ $t("chatroom.profile") }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12 card chat-box-area loadingBg" v-if="isLoadingRooms">
    <div class="loader">
      <div class="multi-ripple">
        <div></div>
        <div></div>
      </div>
      <p class="loading-text">{{ $t("chatroom.loading1") }} <br> {{ $t("chatroom.loading2") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ChatMessage from "./ChatMessage.vue";
import ScrollArea from "./ScrollArea.vue";
import UserCard from "./UserCard.vue";
import {sendMessage, loadNewMessage, readRoom, checkNewRoom} from "../services/chat";
import LocationTicket from "./LocationTicket.vue";

export default {
  components: {
    LocationTicket,
    UserCard,
    ScrollArea,
    ChatMessage,
  },
  name: 'ChatRoom',
  props: {
    flagMode: {
      default: false
    },
    newRoom: {
      type: Boolean,
      default: false
    }
    // room: {
    //   type: Object
    // }
  },
  data () {
    return {
      moderatorMode: false,
      interlocutorLoaded: false,
      isSendingMessage: false,
      pendingMessages: [], // Messages en attente d'envoi
      failedMessages: [], // Messages qui ont échoué à l'envoi
      scanMessagesIds: [],
      coordonnee: false,
      mounted: false,
      isLoadingRooms: false,
      isLoadingMoreMessages: false,
      waitingToRead: [],
      newMessages: {},
      messages: [],
      page: 1,
      hasMoreMessages: true,
      messagesPerPage: 20,
      newInterlocutor: false,
      auth: false,
      ScrollSettings: {
        suppressScrollX: true,
        handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
        wheelSpeed: 1,
        wheelPropagation: false,
        minScrollbarLength: 20
      }
    }
  },
  computed: {
    boutiqueLink () {
      return '/public/' + this.interlocutor.id + '/show'
    },
    newRoomUserId () {
      return this.$route.name === 'room_create' ? this.$route.params.id : false
    },
    interlocutorName () {
      if (!this.interlocutor) {
        return ''
      }
      return this.interlocutor.prenom + ' ' + this.interlocutor.nom.charAt(0)
    },
    initial () {
      if (!this.interlocutor) {
        return ''
      }
      return this.interlocutor.prenom.charAt(0) + "" + this.interlocutor.nom.charAt(0)
    },
    roomId () {
      return this.$route.name === 'room_select' ? this.$route.params.id : false
    },
    interlocutor () {
      if (this.interlocutorLoaded) {
        return this.interlocutorLoaded
      } else if (this.newRoomUserId) {
        return this.newInterlocutor
      } else if (this.formattedMessages.length) {
        return this.formattedMessages[0].receiver.id === this.auth.id ? this.formattedMessages[0].user : this.formattedMessages[0].receiver
      } else {
        return null
      }
    },
    formattedRoomLocations () {
      let inc = 0
      return this.roomLocations.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }).filter((el, i) => {
        return i < 3
      })
    },
    roomLocations () {
      const messages = Array.isArray(this.messages) ? this.messages : []
      return messages.reduce((locations, message) => {
        if (message.demandeLocation) {
          const isExist = locations.find(l => message.demandeLocation && l.id === message.demandeLocation.id)
          if (!isExist) {
            locations.push(message.demandeLocation)
          }
        }
        return locations
      }, [])
    },
    displayMessages() {
      // Créer une copie de tous les messages
      const allMessages = [...this.messages];
      
      // Ajouter les messages en attente
      this.pendingMessages.forEach(pendingMsg => {
        // Ne pas ajouter les messages qui ont déjà été confirmés (existent déjà dans allMessages)
        const messageExists = allMessages.some(m => 
          m.tempId && m.tempId === pendingMsg.tempId
        );
        
        if (!messageExists) {
          allMessages.push(pendingMsg);
        }
      });
      
      // Trier par date de création
      return allMessages.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });
    },
    formattedMessages() {
      let createdAt = []
      let acceptedAt = []
      let paidAt = []
      let canceledAt = []

      return this.displayMessages.map((message) => {
        message['date'] = false
        message['DLCardsAfter'] = []
        message['DLCardsBefore'] = []

        if (message['demandeLocation'] !== null) {
          let DL = message['demandeLocation']
          console.log('DL raw dates:', {
            paidAt: DL['paidAt'],
            canceledAt: DL['canceledAt']
          })

          let statusChanges = []
          
          if (!createdAt.includes(DL['id']) && DL['createdAt']) {
            createdAt.push(DL['id'])
            statusChanges.push({
              timestamp: this.$moment(DL['createdAt']).unix(),
              state: 'created',
              data: DL
            })
          }

          if (!acceptedAt.includes(DL['id']) && DL['acceptedAt']) {
            acceptedAt.push(DL['id'])
            statusChanges.push({
              timestamp: this.$moment(DL['acceptedAt']).unix(),
              state: 'accepted',
              data: DL
            })
          }

          if (!paidAt.includes(DL['id']) && DL['paidAt']) {
            const paidTimestamp = this.$moment(DL['paidAt']).unix()
            console.log('Paid timestamp:', paidTimestamp, new Date(DL['paidAt']))
            paidAt.push(DL['id'])
            statusChanges.push({
              timestamp: paidTimestamp,
              state: 'paid',
              data: DL
            })
          }

          if (!canceledAt.includes(DL['id']) && DL['canceledAt']) {
            const canceledTimestamp = this.$moment(DL['canceledAt']).unix()
            console.log('Canceled timestamp:', canceledTimestamp, new Date(DL['canceledAt']))
            canceledAt.push(DL['id'])
            statusChanges.push({
              timestamp: canceledTimestamp,
              state: 'canceled',
              data: DL
            })
          }

          // Sort status changes by timestamp only
          statusChanges.sort((a, b) => a.timestamp - b.timestamp) // Changed back to ascending order
          console.log('Sorted status changes:', statusChanges.map(s => ({
            state: s.state,
            timestamp: s.timestamp,
            date: new Date(s.timestamp * 1000)
          })))

          message['DLCardsAfter'] = statusChanges.map(change => ({
            ...change.data,
            state: change.state
          }))
          message['DLCardsBefore'] = []
        }

        // Handle date display
        if (this.oldDay === false) {
          message['date'] = message.createdAt
          this.oldDay = this.$moment(message.createdAt).unix()
        }
        if (this.$moment(message.createdAt).unix() - this.oldDay > 60*60*24) {
          message['date'] = message.createdAt
          this.oldDay = this.$moment(message.createdAt).unix()
        }

        return message
      })
    },
    formattedMessagesOld () {
      let newDemandeAdded = []
      let canceledDemandeAdded = []
      const messages = Array.isArray(this.messages) ? this.messages : []
      let currentDL = false
      return messages.reduce((formattedMessages, message, key, messages) => {
        if (message.demandeLocation && !newDemandeAdded.includes(message.demandeLocation.id)) {
          currentDL = {...message.demandeLocation, ...message.locationDates}
          newDemandeAdded.push(message.demandeLocation.id)
          formattedMessages.push({
            type: 'demandeLocation',
            ...message
          })
        }
        formattedMessages.push({
          ...message,
          type: 'message'
        })
        let needToCancel = currentDL.canceledAt && !canceledDemandeAdded.includes(message.demandeLocation.id) && new Date(currentDL.canceledAt) > new Date(message.createdAt)
        if (needToCancel) {
          canceledDemandeAdded.push(message.demandeLocation.id)
          formattedMessages.push({
            type: 'demandeLocation',
            status: 'cancel',
            ...message
          })
        }
        return formattedMessages
      }, [])
    }
  },
  watch: {
    newRoomUserId: {
      immediate: true,
      async handler (val, oldVal) {
        if (val !== oldVal) {
          if (val !== false) {
            await this.loadNewRoom(val)
            if (this.mounted) {
              await this.scrollToEnd(this.$refs.scrollSection)
            }
          }
        }
      }
    },
    roomId: {
      immediate: true,
      async handler (val, oldVal) {
        if (val !== oldVal) {
          this.coordonnee = false
          if (val !== false) {
            await this.loadMessages()
            if (this.mounted) {
              await this.scrollToEnd(this.$refs.scrollSection)
            }
          }
        }
      }
    }
  },
  unmounted() {
    // Supprimer la classe lors de la destruction du composant
    document.body.classList.remove('messagerie-fullscreen-mobile');
    
    // Supprimer l'écouteur d'événement de redimensionnement
    window.removeEventListener('resize', this.handleResize);
    
    this.emitter.off("received-message")
    
    // Nettoyage de l'écouteur d'événement du viewport
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', this.handleVisualViewportResize);
    }
    
    // Nettoyage de l'événement de scroll
    const scrollArea = document.querySelector('.chat-conversation-section');
    if (scrollArea) {
      scrollArea.removeEventListener('scroll', this.handleScroll);
    }
  },
  mounted() {
    this.mounted = true;
    
    // Ajouter une classe spécifique au body pour masquer la topbar sur mobile
    if (window.innerWidth <= 768) {
      document.body.classList.add('messagerie-fullscreen-mobile');
    }
    
    // Ajouter un écouteur d'événement pour le redimensionnement
    window.addEventListener('resize', this.handleResize);
    
    // Ajuster le padding en bas des messages en fonction de la hauteur de la zone de saisie
    this.adjustMessagesPadding();
    
    if (!this.isLoadingRooms) {
      this.scrollToEnd(this.$refs.scrollSection)
      
      // Ajouter un délai supplémentaire pour s'assurer que tout est bien chargé
      setTimeout(() => {
        this.scrollToEnd(this.$refs.scrollSection)
      }, 500)
    }
    
    // Initialisation de tous les textareas pour l'autoexpansion
    this.$nextTick(() => {
      const textareas = document.querySelectorAll('textarea');
      textareas.forEach(textarea => {
        textarea._cachedHeight = Math.max(36, textarea.scrollHeight);
        textarea.style.height = textarea._cachedHeight + 'px';
      });
    });
    
    // Ajout de la gestion du viewport pour le clavier mobile
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.handleVisualViewportResize);
    }
    
    // Ajouter un événement de scroll pour détecter quand l'utilisateur arrive en haut
    this.$nextTick(() => {
      const scrollArea = document.querySelector('.chat-conversation-section');
      if (scrollArea) {
        scrollArea.addEventListener('scroll', this.handleScroll);
      }
    });
    
    this.emitter.on("received-message", async (message) => {
      const hasReceiverMessage = message.messages.filter((m) => {
        return m.receiver.id === this.auth.id
      })
      if (message.messages.length && hasReceiverMessage.length) {
        // console.log('new message')
        // console.log(message)
        await this.pushMessages(message.messages, 'waitingToRead')
        await this.pushMessages(message.messages, 'messages')
        
        // Ajuster l'espace en bas avant de faire défiler
        this.adjustMessagesPadding();
        
        // Assurer que le scroll est fait après la réception des messages
        await this.scrollToEnd()
        
        // Double scroll avec délai pour s'assurer que tout est rendu
        setTimeout(() => {
          this.scrollToEnd()
        }, 200)
        
        if (this.roomId) {
          // console.log('read room')
          await readRoom(this.roomId)
        }
      }
      // console.log('receive message')
      // console.log(message)
    });
    // window.onfocus = function () {
    //   this.isTabActive = true;
    // };
    //
    // window.onblur = function () {
    //   this.isTabActive = false;
    // };
    // console.log(this.$refs.scrollSection)
  },
  methods: {
    // Ajuster le padding en bas des messages en fonction de la hauteur de la zone de saisie
    adjustMessagesPadding() {
      this.$nextTick(() => {
        const chatInput = document.querySelector('.chat-input-container');
        if (!chatInput) return;
        
        // Obtenir la hauteur réelle de l'input
        const inputHeight = chatInput.offsetHeight;
        const isMobile = window.innerWidth <= 768;
        
        // Valeurs différentes pour mobile et desktop
        if (isMobile) {
          // Sur mobile: ajouter hauteur de la navbar + marge de sécurité
          const navHeight = 60;
          const safetyMargin = 20;
          const totalHeight = inputHeight + navHeight + safetyMargin;
          
          document.documentElement.style.setProperty('--chat-input-height-mobile', `${totalHeight}px`);
          document.documentElement.style.setProperty('--chat-input-actual-height', `${inputHeight}px`);
        } else {
          // Sur desktop: juste l'input avec une petite marge
          const desktopMargin = 10;
          const totalHeight = inputHeight + desktopMargin;
          
          document.documentElement.style.setProperty('--chat-input-height-desktop', `${totalHeight}px`);
          document.documentElement.style.setProperty('--chat-input-actual-height', `${inputHeight}px`);
        }
        
        // Ajustement selon la plateforme
        const messagesContainers = document.querySelectorAll('.chat-conversation-section .ps, .messages-container, .chat-conversation-section > div');
        messagesContainers.forEach(container => {
          if (container) {
            if (isMobile) {
              container.style.paddingBottom = `var(--chat-input-height-mobile)`;
            } else {
              container.style.paddingBottom = `var(--chat-input-height-desktop)`;
            }
          }
        });
        
        // Assurer que le scroll est correct après l'ajustement
        this.$nextTick(() => {
          this.scrollToEnd();
        });
      });
      
      // Double vérification après un court délai
      setTimeout(() => {
        const chatInput = document.querySelector('.chat-input-container');
        if (!chatInput) return;
        
        const inputHeight = chatInput.offsetHeight;
        const isMobile = window.innerWidth <= 768;
        
        if (isMobile) {
          const navHeight = 60;
          const safetyMargin = 20;
          const totalHeight = inputHeight + navHeight + safetyMargin;
          
          document.documentElement.style.setProperty('--chat-input-height-mobile', `${totalHeight}px`);
          document.documentElement.style.setProperty('--chat-input-actual-height', `${inputHeight}px`);
        } else {
          const desktopMargin = 10;
          const totalHeight = inputHeight + desktopMargin;
          
          document.documentElement.style.setProperty('--chat-input-height-desktop', `${totalHeight}px`);
          document.documentElement.style.setProperty('--chat-input-actual-height', `${inputHeight}px`);
        }
        
        // Scroll supplémentaire pour s'assurer que tout est bien positionné
        this.scrollToEnd();
      }, 300);
    },
    // Gérer le redimensionnement de l'écran
    handleResize() {
      if (window.innerWidth <= 768) {
        document.body.classList.add('messagerie-fullscreen-mobile');
      } else {
        document.body.classList.remove('messagerie-fullscreen-mobile');
      }
      
      // Réajuster le padding quand la taille de l'écran change
      this.adjustMessagesPadding();
    },
    async loadNewRoom (user_id) {
      this.isLoadingRooms = true
      const newRoomResponse = await checkNewRoom(user_id)
      // console.log('newRoomResponse')
      // console.log(newRoomResponse)
      if (newRoomResponse && newRoomResponse.data && newRoomResponse.data.interlocutor) {
        this.newInterlocutor = newRoomResponse.data.interlocutor
      }
      if (newRoomResponse && newRoomResponse.data && newRoomResponse.data.messages) {
        await this.pushMessages(newRoomResponse.data.messages, 'messages')
      }
      if (newRoomResponse && newRoomResponse.data && newRoomResponse.data.auth) {
        this.auth = newRoomResponse.data.auth
      }
      this.isLoadingRooms = false
    },
    async scanMessages () {
      if (!this.coordonnee && Array.isArray(this.messages)) {
        this.messages.filter((m) => {
          return !this.scanMessagesIds.includes(m.id)
        }).forEach((m) => {
          // if (m.content) {
            const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          const phoneRegex = /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/
          // }
          const hasMailRegex = mailRegex.test(m.content)
          const hasPhone = phoneRegex.test(m.content)
          this.scanMessagesIds.push(m.id)
          if (hasMailRegex || hasPhone) {
            this.coordonnee = true
          }
        })
      }
    },
    async autosize (evt) {
      const el = evt.target;
      
      // Réinitialiser la hauteur pour obtenir la hauteur correcte du contenu
      el.style.height = 'auto';
      
      // Définir la nouvelle hauteur en fonction du contenu
      const newHeight = Math.max(24, el.scrollHeight);
      el.style.height = newHeight + 'px';
      
      // Réajuster le padding de la zone de messages quand la hauteur de la zone de saisie change
      this.$nextTick(() => {
        this.adjustMessagesPadding();
        this.scrollToEnd();
      });
    },
    async scrollToEnd(el = false) {
      if (el === false) {
        el = this.$refs.scrollSection;
      }
      
      // Premier scroll immédiat
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
      
      // Utiliser requestAnimationFrame pour s'assurer que le DOM est prêt
      requestAnimationFrame(() => {
        // Tenter de scroller à la fois le conteneur principal et le ScrollArea interne
        if (el) {
          el.scrollTop = el.scrollHeight;
        }
        
        // Sélecteurs plus précis et complets pour cibler tous les conteneurs de messages possibles
        const scrollContainers = [
          document.querySelector('.chat-conversation-section .ps'),
          document.querySelector('.chat-conversation-section > div'),
          document.querySelector('.messages-container'),
          document.querySelector('.ps__content')
        ];
        
        scrollContainers.forEach(container => {
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        });
      });
    },
    async back () {
      await this.$router.push('/user/messagerie')
    },
    async loadNewMessages () {
      // console.log('load New message')
      const rep = await loadNewMessage()

    },
    async pushMessages (messages, key = 'messages') {
      messages.forEach((v) => {
        const allreadyExist = this[key].findIndex((r) => {
          return r.id === v.id
        })
        // console.log('allreadyExist')
        // console.log(allreadyExist)
        if (allreadyExist === -1) {
          this[key].push(v)
        } else {
          this[key][allreadyExist] = v
        }
      })
    },
    async sendMessage () {
      if (!this.isSendingMessage && typeof this.newMessages[this.roomId] === 'string' && this.newMessages[this.roomId].trim().length) {
        this.isSendingMessage = true;
        const messageContent = this.newMessages[this.roomId].trim();
        this.newMessages[this.roomId] = '';
        
        // Créer un ID temporaire unique pour ce message
        const tempId = 'temp_' + Date.now() + '_' + Math.floor(Math.random() * 1000);
        
        // Créer un message temporaire pour affichage immédiat
        const tempMessage = {
          id: tempId,
          tempId: tempId,
          content: `<p>${messageContent.replace(/\n/g, '<br>')}</p>`,
          createdAt: new Date().toISOString(),
          user: this.auth,
          receiver: this.interlocutor,
          isPending: true,
          isFailed: false,
          demandeLocation: null
        };
        
        // Ajouter à la liste des messages en attente
        this.pendingMessages.push(tempMessage);
        
        // Scroll vers le bas pour montrer le nouveau message
        this.$nextTick(() => {
          this.scrollToEnd();
        });
        
        try {
          const rep = await sendMessage(messageContent, this.roomId, this.auth.id, this.interlocutor.id);
          
        if (rep && rep.data && rep.data.success) {
            // Mettre à jour l'UI en cas de succès
          if (rep.data.room) {
              this.emitter.emit('UpdateRoom', rep.data.room);
            }
            
            if (rep.data.message && rep.data.message.length > 0) {
              // Ajouter les propriétés pour lier ce message réel au message temporaire
              rep.data.message[0].tempId = tempId;
              
              // Ajouter le message confirmé à notre liste de messages
              await this.pushMessages(rep.data.message);
              
              // Retirer le message temporaire de la liste des messages en attente
              this.pendingMessages = this.pendingMessages.filter(m => m.tempId !== tempId);
            }
          } else {
            // Marquer le message comme ayant échoué
            this.pendingMessages = this.pendingMessages.map(m => {
              if (m.tempId === tempId) {
                return { ...m, isPending: false, isFailed: true };
              }
              return m;
            });
            
            // Ajouter à la liste des messages ayant échoué
            this.failedMessages.push(tempMessage);
          }
        } catch (error) {
          console.error('Erreur lors de l\'envoi du message:', error);
          
          // Marquer le message comme ayant échoué
          this.pendingMessages = this.pendingMessages.map(m => {
            if (m.tempId === tempId) {
              return { ...m, isPending: false, isFailed: true };
            }
            return m;
          });
          
          // Ajouter à la liste des messages ayant échoué
          this.failedMessages.push(tempMessage);
        } finally {
          this.isSendingMessage = false;
        }
      }
    },
    async loadMessages () {
      if (this.roomId) {
        this.isLoadingRooms = true;
        this.page = 1; // Réinitialiser la page courante
        this.hasMoreMessages = true; // Réinitialiser le flag
        
        let url = `/user/room/messages/${this.roomId}?page=${this.page}&limit=${this.messagesPerPage}`;
        if (this.$lightyshareConfig && this.$lightyshareConfig.api_base_url) {
          url = this.$lightyshareConfig.api_base_url + url;
        }
        
        const response = await axios.get(url);
        
        if (response.status === 200) {
          // Vérifier s'il y a potentiellement plus de messages
          if (response.data.messages.length < this.messagesPerPage) {
            this.hasMoreMessages = false;
          }
          
          this.messages = response.data.messages;
          this.auth = response.data.auth;
          this.moderatorMode = response.data.mod ? response.data.mod : false;
          this.interlocutorLoaded = response.data.interlocutor;
          
          this.scanMessages().then(() => {
            // console.log('message scanned')
          });
          
          // Assurer que le scroll est fait après le chargement des messages
          this.$nextTick(() => {
            this.adjustMessagesPadding(); // Ajuster l'espace en bas
            this.scrollToEnd();
            // Double scroll avec délai pour s'assurer que tout est rendu
            setTimeout(() => {
              this.scrollToEnd();
            }, 300);
          });
        }
        
        this.isLoadingRooms = false;
      }
    },
    // Gestion de la touche Entrée (uniquement sur desktop)
    async handleEnterKey(evt) {
      // Sur mobile, ne pas intercepter la touche Entrée
      if (window.innerWidth <= 768) return;
      
      await this.sendMessage();
    },
    // Gestion du redimensionnement du viewport (pour le clavier mobile)
    handleVisualViewportResize() {
      const chatInput = document.querySelector('.chat-input-container');
      if (!chatInput) return;
      
      if (window.visualViewport.height < window.innerHeight * 0.8) {
        // Clavier ouvert
        chatInput.style.position = 'absolute';
        // Ajout d'un offset de 4px pour éviter le chevauchement
        chatInput.style.bottom = `${window.innerHeight - window.visualViewport.height + 4}px`;
      } else {
        // Clavier fermé
        chatInput.style.position = '';
        chatInput.style.bottom = '';
      }
    },
    async handleScroll(event) {
      // Vérifier si l'utilisateur est près du haut de la conversation
      const { scrollTop } = event.target;
      
      // Si on est proche du haut (moins de 200px) et qu'on n'est pas déjà en train de charger
      // et qu'il y a potentiellement plus de messages
      if (scrollTop < 200 && !this.isLoadingMoreMessages && this.hasMoreMessages) {
        await this.loadMoreMessages();
      }
    },
    async loadMoreMessages() {
      if (this.isLoadingMoreMessages || !this.hasMoreMessages || !this.roomId) return;
      
      this.isLoadingMoreMessages = true;
      
      // Garder la position de défilement actuelle
      const scrollArea = document.querySelector('.chat-conversation-section');
      const scrollHeight = scrollArea ? scrollArea.scrollHeight : 0;
      const scrollPosition = scrollArea ? scrollArea.scrollTop : 0;
      
      // Incrémenter la page pour charger les messages plus anciens
      this.page++;
      
      try {
        let url = `/user/room/messages/${this.roomId}?page=${this.page}&limit=${this.messagesPerPage}`;
        if (this.$lightyshareConfig && this.$lightyshareConfig.api_base_url) {
          url = this.$lightyshareConfig.api_base_url + url;
        }
        
        const response = await axios.get(url);
        
        if (response.status === 200 && response.data.messages) {
          // Si on reçoit moins de messages que demandé, c'est qu'on a atteint la fin
          if (response.data.messages.length < this.messagesPerPage) {
            this.hasMoreMessages = false;
          }
          
          // Ajouter les messages au début du tableau
          const oldMessages = [...response.data.messages].reverse();
          this.messages = [...oldMessages, ...this.messages];
          
          // Maintenir la position de défilement après avoir ajouté de nouveaux messages
          this.$nextTick(() => {
            if (scrollArea) {
              const newScrollHeight = scrollArea.scrollHeight;
              const heightDiff = newScrollHeight - scrollHeight;
              scrollArea.scrollTop = scrollPosition + heightDiff;
            }
          });
        } else {
          // Aucun message retourné, on a atteint la fin
          this.hasMoreMessages = false;
        }
      } catch (error) {
        console.error('Erreur lors du chargement des messages supplémentaires', error);
        this.hasMoreMessages = false;
      } finally {
        this.isLoadingMoreMessages = false;
      }
    },
    // Méthode pour réessayer d'envoyer un message ayant échoué
    async retryMessage(tempId) {
      // Trouver le message qui a échoué
      const failedMessage = this.failedMessages.find(m => m.tempId === tempId);
      
      if (failedMessage) {
        // Extraire le contenu du message
        const content = failedMessage.content.replace(/<\/?p>/g, '').replace(/<br\s*\/?>/g, '\n');
        
        // Marquer comme en cours d'envoi
        this.pendingMessages = this.pendingMessages.map(m => {
          if (m.tempId === tempId) {
            return { ...m, isPending: true, isFailed: false };
          }
          return m;
        });
        
        // Retirer de la liste des messages ayant échoué
        this.failedMessages = this.failedMessages.filter(m => m.tempId !== tempId);
        
        // Réessayer l'envoi
        this.isSendingMessage = true;
        
        try {
          const rep = await sendMessage(content, this.roomId, this.auth.id, this.interlocutor.id);
          
          if (rep && rep.data && rep.data.success) {
            // Mettre à jour l'UI en cas de succès
            if (rep.data.room) {
              this.emitter.emit('UpdateRoom', rep.data.room);
            }
            
            if (rep.data.message && rep.data.message.length > 0) {
              // Ajouter les propriétés pour lier ce message réel au message temporaire
              rep.data.message[0].tempId = tempId;
              
              // Ajouter le message confirmé à notre liste de messages
              await this.pushMessages(rep.data.message);
              
              // Retirer le message temporaire de la liste des messages en attente
              this.pendingMessages = this.pendingMessages.filter(m => m.tempId !== tempId);
            }
          } else {
            // Marquer le message comme ayant échoué à nouveau
            this.pendingMessages = this.pendingMessages.map(m => {
              if (m.tempId === tempId) {
                return { ...m, isPending: false, isFailed: true };
              }
              return m;
            });
            
            // Ajouter à la liste des messages ayant échoué
            this.failedMessages.push(failedMessage);
          }
        } catch (error) {
          console.error('Erreur lors de la réessai d\'envoi du message:', error);
          
          // Marquer le message comme ayant échoué à nouveau
          this.pendingMessages = this.pendingMessages.map(m => {
            if (m.tempId === tempId) {
              return { ...m, isPending: false, isFailed: true };
            }
            return m;
          });
          
          // Ajouter à la liste des messages ayant échoué
          this.failedMessages.push(failedMessage);
        } finally {
          this.isSendingMessage = false;
        }
      }
    }
  }
}
</script>

<style lang="scss">
//$charade: #282a37;
$bluebell: rgb(231, 29, 65, 0.5);

// Styles pour cacher la top bar sur mobile de façon plus ciblée
body.messagerie-fullscreen-mobile {
  // Forcer le retrait de la barre supérieure et l'ajustement du contenu
  .topbar,
  .global-search-bar,
  .lightyshare-header,
  .search-header,
  header > .container,
  .header-container,
  header nav.navbar,
  #top-header,
  #app > header,
  .app-header,
  header[role="banner"],
  .top-fixed-container {
    display: none !important;
  }
  
  // Ajuster le padding global de la page pour utiliser tout l'espace
  padding-top: 0 !important;
  margin-top: 0 !important;
  
  // Forcer les styles du contenu principal
  #app > main,
  main.main-content,
  #main-content,
  .main-container,
  .page-content {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  
  // S'assurer que le composant de chat occupe tout l'espace vertical
  .chat-box-area {
    height: 100vh !important;
    max-height: none !important;
  }
}

// Nouveaux styles pour la zone de saisie restructurée
.chat-input-container {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #eaeaea;
  padding: 12px 16px 30px 16px;
  z-index: 100;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box;
  
  // Styles spécifiques pour mobile
  @media (max-width: 768px) {
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 0;
    background: #fff;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.05);
    z-index: 100;
  }
  
  // Styles pour le certificat
  .certification-badge {
    margin-bottom: 8px;
    
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
}

// Styles pour le conteneur de l'input
.message-input {
  display: flex;
  align-items: flex-end;
  background-color: #f1f3f4;
  border-radius: 24px;
  padding: 4px 16px 4px 16px;
  
  textarea {
    flex: 1;
    border: none;
    background: transparent;
    padding: 10px 0;
    font-size: 14px;
    color: #202124;
    outline: none;
    resize: none;
    max-height: 120px;
    min-height: 24px;
    line-height: 1.4;
    font-family: inherit;
    
    &:focus {
      outline: none;
    }
    
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    
    // Styles spécifiques pour mobile
    @media (max-width: 768px) {
      font-size: 16px; // Éviter le zoom sur iOS
    }
  }
}

// Styles pour le bouton d'envoi
.send-button {
  background-color: #FF4757;
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 8px;
  
  // Styles pour mobile
  @media (max-width: 768px) {
    width: 44px;
    height: 44px;
  }
  
  &:hover {
    transform: scale(1.05);
    background-color: darken(#FF4757, 10%);
  }
  
  &:active {
    transform: scale(0.95);
  }
  
  &:disabled {
    background-color: #f1f3f4;
    color: #9aa0a6;
    cursor: not-allowed;
  }
  
  .send-icon {
    width: 18px;
    height: 18px;
    
    // Augmenter la taille sur mobile
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
  
  .button-spinner {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    
    // Augmenter la taille sur mobile
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      border-width: 3px;
    }
  }
}

// Styles pour la zone de conversation
.chat-conversation-section {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  background-color: #f5f5f5;
  position: relative; // Ajout pour un meilleur positionnement
  
  // Styles spécifiques pour desktop
  @media (min-width: 769px) {
    height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
  }
  
  // Styles spécifiques pour mobile
  @media (max-width: 768px) {
    height: calc(100vh - 60px - 60px); // En-tête + barre de navigation
    padding-bottom: 15px !important;
    margin-bottom: 0 !important;
    overflow-y: auto;
  }
  
  // Ajustement spécifique quand la topbar est masquée
  body.messagerie-fullscreen-mobile & {
    @media (max-width: 768px) {
      height: calc(100vh - 60px - 60px) !important; // Juste en-tête + barre de navigation
    }
  }
  
  // S'assurer que le contenu remplit bien tout l'espace
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    
    // Ajouter un padding en bas pour éviter que les messages soient masqués par la zone d'input
    padding-bottom: 120px !important; // Augmenté de 80px à 120px pour mobile
    
    @media (max-width: 768px) {
      padding-bottom: 150px !important; // Augmenté encore plus sur mobile
    }
  }
}

// Ajustement pour la carte principale
.chat-box-area {
  display: flex !important;
  flex-direction: column;
  height: calc(100vh - 56px); // Hauteur totale moins la barre de navigation
  background-color: #f5f5f5; // Même gris clair pour tout le conteneur
  overflow: hidden; // Éviter les barres de défilement sur desktop aussi
  
  @media (max-width: 768px) {
    overflow: hidden; // Éviter les barres de défilement doubles
    background-color: #f5f5f5; // S'assurer que le fond est appliqué sur mobile
    height: 100vh; // Utiliser toute la hauteur disponible
    padding-bottom: 0; // Réduire le padding excessif
    position: relative; // Ajout pour le positionnement absolu de l'input
  }
}

// Ajuster la taille de l'en-tête du chat sur mobile
.phone-chat-header {
  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  }
  
  // Spécifique quand la topbar est masquée
  body.messagerie-fullscreen-mobile & {
    @media (max-width: 768px) {
      top: 0;
      border-top: none;
    }
  }
}

.loading-text {
  text-align: center;
  color: #A3A3A3;
}
.loadingBg {
  display: flex !important;
  //flex-direction: column;
  align-items: center;
  justify-content: center;
}
.multi-ripple {
  width: 2.6rem;
  height: 2.6rem;
  //margin-top: 50%;
  margin: 0.5rem 2rem;

  div {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 0.3rem solid $bluebell;
    animation: 1.5s ripple infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}
@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

// Styles pour s'assurer que la barre de navigation reste au-dessus
@media (max-width: 768px) {
  // Ciblage de la barre de navigation inférieure
  .navbar-mobile,
  .navbar-bottom,
  nav.bottom-nav,
  .bottom-navigation {
    position: fixed !important;
    bottom: 0 !important;
    z-index: 1000 !important; // Z-index élevé pour rester au-dessus
  }
}

// Ajustement global pour le conteneur de chat sur desktop
.col-lg-6.card.chat-box-area {
  @media (min-width: 769px) {
    padding-bottom: 0; // Suppression du padding en bas
    display: flex;
    flex-direction: column;
    
    // Ajuster la hauteur de l'en-tête sur desktop
    .phone-chat-header {
      flex-shrink: 0; // Ne pas rétrécir
      min-height: 60px;
    }
    
    // Ajuster la hauteur du corps sur desktop
    .card-body.chat-conversation-section {
      flex: 1; // Prendre tout l'espace disponible
      overflow-y: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
    // Ajuster la zone de saisie sur desktop
    .chat-input-container {
      flex-shrink: 0; // Ne pas rétrécir
      position: sticky; // Garder en sticky même sur desktop
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 0; // Supprimer la marge
    }
  }
}

// Style supplémentaire pour les messages
:deep(.sent-msg),
:deep(.recive-msg) {
  @media (min-width: 769px) {
    margin-bottom: 12px; // Plus d'espace entre les messages sur desktop
  }
}

// Amélioration de la focusabilité de la zone de saisie
.message-input-form .message-input:focus {
  box-shadow: 0 0 0 2px rgba(252, 34, 73, 0.1); // Léger halo lors du focus
  border-color: #fc2249;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;
}

// Styles pour les indicateurs de chargement des messages précédents
.loading-previous-messages, .load-more-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px auto;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.load-more-messages {
  cursor: pointer;
  background-color: rgba(0,0,0,0.03);
  border-radius: 20px;
  padding: 8px 16px;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: rgba(0,0,0,0.07);
    color: #333;
  }
}

.loading-spinner {
  display: inline-block;
  margin-right: 10px;
  
  .spinner {
    width: 18px;
    height: 18px;
    border: 2px solid rgba(231, 29, 65, 0.2);
    border-top-color: rgba(231, 29, 65, 0.8);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  @media (max-width: 768px) {
    padding-bottom: var(--chat-input-height-mobile) !important;
  }
  
  @media (min-width: 769px) {
    padding-bottom: var(--chat-input-height-desktop) !important;
  }
}

.messages-container :deep(.message-wrapper) {
  margin-bottom: 8px;
}

.messages-container :deep(.message-wrapper.sent) {
  align-self: flex-end;
}

.messages-container :deep(.message-wrapper.received) {
  align-self: flex-start;
}

// Style pour la div chat-right-box
.chat-right-box {
  background: white;
  padding: 24px 0 0 8px;
  border-left: 1px solid #eaeaea
}

// Ajouter les variables CSS globales
:root {
  --chat-input-height-mobile: 120px;
  --chat-input-height-desktop: 70px;
  --chat-input-actual-height: 60px;
}

// Styles pour la zone de conversation
.chat-conversation-section {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  background-color: #f5f5f5;
  position: relative;
  
  // Styles spécifiques pour desktop
  @media (min-width: 769px) {
    height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
  }
  
  // Styles spécifiques pour mobile
  @media (max-width: 768px) {
    height: calc(100vh - 120px);
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    overflow-y: auto;
  }
  
  // S'assurer que le contenu remplit bien tout l'espace
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    
    // Utilisation des variables CSS pour le padding
    padding-bottom: var(--chat-input-height-desktop) !important;
  }
}

// Ajustement pour la carte principale
.chat-box-area {
  display: flex !important;
  flex-direction: column;
  height: calc(100vh - 56px);
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
  
  @media (max-width: 768px) {
    height: 100vh;
    padding-bottom: 0;
  }
}

// Ajuster le positionnement de la zone d'input
.chat-input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #eaeaea;
  padding: 12px 16px;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  
  // Ajustement spécifique pour mobile
  @media (max-width: 768px) {
    bottom: 60px; // Au-dessus de la barre de navigation
    box-shadow: 0 -2px 5px rgba(0,0,0,0.05);
  }
  
  // Ré-ajuster pour desktop
  @media (min-width: 769px) {
    position: sticky;
    bottom: 0;
    padding-bottom: 20px;
  }
}
</style>
