<template>
  <!-- Date divider -->
  <div class="date" style="text-align: center;" v-if="formattedDate !== false">
    <p class="message-date-box">{{formattedDate}}</p>
  </div>
  
  <!-- Location cards before message -->
  <LocationCard v-for="card in DLCardsBefore" :DLCard="card"/>
  
  <!-- Message wrapper with appropriate class based on sender -->
  <div :class="['message-wrapper', isOwner ? 'received' : 'sent']">
    <!-- Message bubble -->
    <div class="message" v-html="message.content"></div>
    
    <!-- Message time -->
    <div class="message-time">{{formattedHour}}</div>
    
    <!-- Message status indicators -->
    <div v-if="message.isPending" class="message-status message-pending">
      <span class="message-pending-icon"></span>
      <span>Envoi...</span>
    </div>
    <div v-if="message.isFailed" class="message-status message-failed" @click="retryMessage">
      <i class="fas fa-exclamation-circle message-failed-icon"></i>
      <span>Échec - Réessayer</span>
    </div>
  </div>
  
  <!-- Location cards after message -->
  <LocationCard v-for="card in DLCardsAfter" :DLCard="card" />
</template>

<script>
import LocationCard from "./LocationCard.vue"

export default {
  name: 'ChatMessage',
  components: {
    LocationCard
  },
  props: {
    user_id: {
      type: [String, Number]
    },
    message: {
      type: Object
    }
  },
  data () {
    return {
      messages: [],
      DLCards: []
    }
  },
  computed: {
    formattedDate () {
      if(this.message.date !== false)
      {
        return this.$moment(this.message.date).format('dddd D MMMM')
      } else {
        return false
      }
    },
    formattedHour () {
      return this.$moment(this.message.createdAt).format('HH:mm')
    },
    initial () {
      return this.owner.prenom.charAt(0) + "" + this.owner.nom.charAt(0)
    },
    image () {
      return this.message.user && this.message.user.ImageProfil && this.message.user.ImageProfil.imageName
    },
    isOwner () {
      return this.message && this.message.user && this.user_id !== this.message.user.id
    },
    owner () {
      return this.message.user
    },
    DLCardsBefore () {
      return this.message.DLCardsBefore
    },
    DLCardsAfter () {
      return this.message.DLCardsAfter
    },
  },
  methods: {
    retryMessage() {
      // Émettre un événement pour informer le parent qu'il faut réessayer ce message
      this.$emit('retry-message', this.message.tempId);
    }
  }
}
</script>

<style scoped>
/* Message date divider */
.message-date-box {
  margin: 0 auto 15px auto;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1px 10px;
  display: inline-block;
  font-size: 12px;
  color: #080025;
}

/* Message wrapper */
.message-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  position: relative;
  margin-bottom: 12px;
}

.message-wrapper.sent {
  align-self: flex-end;
  margin-right: 4px;
}

.message-wrapper.received {
  align-self: flex-start;
  padding-left: 12px;
  margin-left: 8px;
}

/* Message bubble */
.message {
  padding: 12px 16px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  word-break: break-word;
  position: relative;
  max-width: 100%;
}

.sent .message {
  background-color: #2196F3;
  color: white;
  border-bottom-right-radius: 4px;
  align-self: flex-end;
  margin-left: auto;
}

.sent .message :deep(a) {
  color: #ffffff;
  text-decoration: underline;
}

.received .message {
  background-color: white;
  color: #202124;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
}

.received .message :deep(a) {
  color: #2196F3;
}

.message :deep(p) {
  margin: 0;
  padding: 0;
}

/* Message time */
.message-time {
  font-size: 11px;
  color: #9aa0a6;
  margin-top: 4px;
  align-self: flex-end;
}

.received .message-time {
  margin-left: 8px;
}

/* Message status indicators */
.message-status {
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 2px;
  align-self: flex-end;
}

.message-pending {
  color: #9aa0a6;
}

.message-failed {
  color: #e74c3c;
  cursor: pointer;
}

.message-pending-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1.5px solid rgba(153, 153, 153, 0.3);
  border-top-color: #9aa0a6;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-right: 4px;
}

.message-failed-icon {
  margin-right: 4px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
