<template>
  <div class="box click-box" @click="goToPanier">
    <div class="text" >
      <h5 v-if="title">
        {{titleFormat}}
      </h5>
      <h6>
        <a :class="'btn btn-primary ' + locationStatusMeta.class" @click.prevent>{{locationStatusMeta.msg}}</a>
      </h6>
    </div>
  </div>
</template>

<script>
const base_url = 'http://www.lightyshare.com'
export default {
  name: 'LocationTicket',
  props: {
    auth: {
      type: [Object, Boolean]
    },
    demandeLocation: {
      type: Object
    }
  },
  methods: {
    goToPanier () {
      const url = '/locataire/location/' + this.demandeLocation.id
      window.open(url, '_blank').focus();
    }
  },
  computed: {
    locationLink () {

    },
    locationStatusMeta () {
      const statusTexts = [
        {
          class: 'Demande',
          link: '#',
          msg:   this.$root.$t("chatroom.rent.sent")
        },
        {
          class: 'Demande',
          link: '#',
          msg:  this.$root.$t('chatroom.rent.accepted')
        },
        {
          class: 'Demande',
          link: '#',
          msg:  this.$root.$t('chatroom.rent.paid')
        },
        {
          class: 'Annulee',
          link: '#',
          msg:  this.$root.$t('chatroom.rent.canceled')
        },
        {
          class: 'Terminee',
          link: '#',
          msg:  this.$root.$t('chatroom.rent.finished')
        }
      ]
      return statusTexts[this.locationStatus - 1]
    },
    locationStatus () {
      let status = 1
      if (this.demandeLocation.canceled) {
        status = 4
      } else if (this.demandeLocation.Termined) {
        status = 5
      } else if (this.demandeLocation.acceptedAt) {
        status = 2
      } else if (this.demandeLocation.paidAt) {
        status = 3
      }
      return status
    },
    titleFormat () {
      if (this.title) {
        return this.title.substring(0, 20)
      } else {
        return ''
      }
    },
    title () {
      return this.demandeLocation && this.demandeLocation.titreLocation ? this.demandeLocation.titreLocation : this.demandeLocation.annonce && this.demandeLocation.annonce.title
    },
    annonce () {
      if (this.demandeLocation && this.demandeLocation.annonce) {
        return this.demandeLocation.annonce
      } else if (this.demandeLocation.panier && this.demandeLocation.panier.paniersAnnonce && this.demandeLocation.panier.paniersAnnonce[0]) {
        return this.demandeLocation.panier.paniersAnnonce[0].annonces
      }
    },
    imageLocation () {
      const hasProprioId =  this.demandeLocation.proprietaire && this.demandeLocation.proprietaire.id
      const hasAnnonceId =  this.annonce && this.annonce.id
      const hasImageAnnonceName = this.annonce && this.annonce.imagesannonce && this.annonce.imagesannonce.length && this.annonce.imagesannonce[0].imageName
      return hasAnnonceId && hasProprioId && hasImageAnnonceName && base_url + '/images-upload/' + hasProprioId + '/annonces/' + hasAnnonceId + '/' + hasImageAnnonceName
    }
  }
}
</script>

<style scoped>
  .click-box {
    cursor: pointer;
  }
</style>
